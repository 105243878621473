import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MentionModule } from 'angular-mentions';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import {
  MultiSelectAllModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';

import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { PostReviewModelComponent } from './layout/post-review-model/post-review-model.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { CreateProfileComponent } from '../profile/create-profile/create-profile.component';
import { DynamicFieldsComponent } from '../profile/create-profile/dynamic-fields/dynamic-fields.component';

import { ShortenPipe } from '../core/pipes/sorten.pipe';

import { maxValidatorDirective } from '../core/validation/max.validator';
import { blankValidatorDirective } from '../core/validation/blank.validator';
import { dataTypeValidatorDirective } from '../core/validation/datatypeValidator';
import { SortByPipe } from '../core/pipes/SortByPipe.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AmpVideoComponent } from './amp-video/amp-video.component';
import { ImageOnlyDirective } from '../core/validation/imageOnly.validator';
import { NgxLinkifyjsHtmlPipe } from '../core/pipes/linkify-html.pipe';
import { LoaderComponent } from './loader/loader.component';
import { NoLeadingWhitespaceValidatorDirective } from '../core/validation/noleadingwhitespace';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    PostReviewModelComponent,
    ThankyouComponent,
    CreateProfileComponent,
    DynamicFieldsComponent,
    maxValidatorDirective,
    blankValidatorDirective,
    dataTypeValidatorDirective,
    ImageOnlyDirective,
    ShortenPipe,
    SortByPipe,
    NgxLinkifyjsHtmlPipe,
    AmpVideoComponent,
    LoaderComponent,
    NoLeadingWhitespaceValidatorDirective,
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatProgressSpinnerModule,
    MentionModule,
    AutocompleteLibModule,
    TagInputModule,
    DatePickerModule,
    MultiSelectAllModule,
    ImageCropperModule,
    MultiSelectModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatProgressSpinnerModule,
    MentionModule,
    AutocompleteLibModule,
    TagInputModule,
    DatePickerModule,
    MultiSelectAllModule,
    MultiSelectModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    PostReviewModelComponent,
    ThankyouComponent,
    CreateProfileComponent,
    DynamicFieldsComponent,
    maxValidatorDirective,
    blankValidatorDirective,
    dataTypeValidatorDirective,
    ImageOnlyDirective,
    ShortenPipe,
    SortByPipe,
    NgxLinkifyjsHtmlPipe,
    AmpVideoComponent,
    LoaderComponent,
    NoLeadingWhitespaceValidatorDirective,
  ],
})
export class SharedModule {}
