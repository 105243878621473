import { Notification } from './../../../core/models/notification.model';
import { NotificationService } from './../../../core/services/notification.service';
import { WebsocketService } from './../../../core/services/websocket.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Profile } from 'src/app/core/models/profile.model';
import { AuthService } from '../../../core/services/auth.service';
import { DesignService } from 'src/app/core/services/design.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { LoaderService } from 'src/app/core/loader.service';
import { Subscription } from 'rxjs';
import { NgxLinkifyOptions } from 'src/app/core/pipes/ngx-linkifyjs.interface';
import { formatDate } from '@angular/common';
import { User, UserService } from 'src/app/core';
import { ReportBugFeedbackDTO } from '../../../core/models/reportBugFeedback.modal';
import { NgForm } from '@angular/forms';
import Shepherd from 'shepherd.js';

declare var $: any;
declare var Veriff: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerStyle: string;
  headerStyleSubscription: any;
  searchValue = '';
  searchSuggetionList: any[] = [];
  defaultProfile = new Profile();
  public profile = new Profile();
  firstLogin: boolean = false;
  verified: boolean = false;
  nonVerifiedUser = { firstName: '', lastName: '', userName: '' };
  logoutBtn: boolean = false;
  loginBtn: boolean = false;
  clickEventsubscription: Subscription;
  notificationCount: number = 0;
  notificationList = new Array<Notification>();
  pageRequest: Number = 0;
  loading: string = 'none';
  isInAppear: boolean = false;
  welcomeMessage: boolean = false;
  todayDate: any;
  value: any;
  timestamp: number = Date.now();
  reportBugFeedbackDTO: ReportBugFeedbackDTO = new ReportBugFeedbackDTO();
  isHomepage: boolean = false;
  showSuggestions: boolean = false;
  
  navigation: any;
  textVerified = false;
  firstClick: boolean = false;
  invalidOTP: boolean = false;
  otpSent = false;
  otpResent = false;
  passwordMeter = false;

  public countryCode: string = 'us';
  public phoneNumber: string = '';
  errorMsg = null;
  accountSetUp = 0;
  verifyIdentity = 70;

  // activation code array
  actCodeArr: string[] = new Array(6);
  otpCodeArr: string[] = ['', '', '', ''];
  toDos: string[] = [];
  otp: string = '';
  userId: any;
  // error message from response
  errMsg: string = '';
  invalidPhone = false;
  // flag denoting invalid sign up state.
  invalidSignUp: boolean = false;
  // flag denoting wrong activation code.
  incorrectActCode: boolean = false;
  activationsent: boolean = false;


    // check user id fully verified or not
    fullyVerified = false;
    existPhoneNumber = false;
    validAge: boolean = true;
    veriffVerified = false;
    veriffStatus: string = '';
    veriffSessionId: any; 
    veriffErrMsg: string[] = [];

  email: string = '';
  verificationCode: string = ';'
  phoneNoErrMsg: string = '';


  public getUser() {
    return this.authService.getUser();
  }

  user = new User();

  options: NgxLinkifyOptions = {
    className: '',
    attributes(href: any, type: string) {
      type = 'linkify linkify-' + type;
      return { routerLink: href, class: type };
    },
    formatHref(href, type) {
      if (type === 'mention') {
        href = '/profile/view-profile/' + href.substring(1);
      }
      return href;
    },
    ignoreTags: ['script', 'style'],
    nl2br: true,
  };
  representativeList: Array<string> = [];

  constructor(
    private authService: AuthService,
    private designService: DesignService,
    private profileService: ProfileService,
    private router: Router,
    private loaderService: LoaderService,
    private websocketService: WebsocketService,
    private notificationService: NotificationService,
    private userService: UserService
  ) {
    this.getUserDetails();
    this.headerStyle = designService.headerStyle;
    this.headerStyleSubscription =
      this.designService.headerStyleChange.subscribe((value) => {
        this.headerStyle = value;
      });

    this.clickEventsubscription = this.designService
      .getClickEvent()
      .subscribe(() => {
        this.ngOnInit();
      });

    //whenever data recieved from server this will update notification count
    // this.websocketService.updateNotificationCountViaWebSocket$.subscribe((value) => {
    //  console.log('websocket data=', value);
    // let dataViaWebSocketFromServer = JSON.parse(value.toString());
    // if (dataViaWebSocketFromServer.data !== null && dataViaWebSocketFromServer.success === 0) {
    //  this.notificationCount = dataViaWebSocketFromServer.data;
    //  this.getNotifications();
    //}
    //console.log('Data recieved via web socket from server' + value);
    //console.log('Total notification count=' + this.notificationCount);
    //});
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      // Check if the current URL is the homepage
      this.isHomepage = this.router.url === '/homepage';
    });
    this.todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.user = this.authService.getUser();

    if (this.user != undefined) {
      this.email = this.user.email;
    }

    if (this.user != undefined && this.user.textVerified) {
      this.phoneNumber = this.user.phoneNumber;
    }

    if (this.authService.getLogflag()) {
      const params = new HttpParams().set(
        'profileId',
        this.user.defaultProfileId
      ); // getting profile Id from route.
      // APi call
      this.profileService.getProfileDetails(params).subscribe((res) => {
        if (res.success === 0) {
          let temp = res.data.profileImage;
          if (
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpeg' ||
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'png' ||
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpg' ||
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'test'
          ) {
            this.defaultProfile.profileImage = temp;
          } else {
            this.defaultProfile.profileImage = '';
          }

          if (
            this.defaultProfile.profileImage.includes(
              'bioguide.congress.gov'
            ) == true
          ) {
            this.defaultProfile.profileImage = '';
          }
        }
      });
    }

    if (this.user == null || this.authService.getLogflag() == null) {
      this.logoutBtn = true;
      this.loginBtn = false;
    } else {
      this.logoutBtn = false;
      this.loginBtn = true;
    }

    if (this.user != null || this.authService.getLogflag()) {
      console.log('Here : ' + JSON.stringify(this.user));
      this.firstLogin = this.user['firstLogIn'];
      this.verified = this.user.verified;
      this.defaultProfile.profileName = this.user.defaultProfileName;
      let temp = this.user.defaultProfileImage;

      if (temp != null) {
        if (
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpeg' ||
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'png' ||
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpg' ||
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'test'
        ) {
          this.defaultProfile.profileImage = temp;
        } else {
          this.defaultProfile.profileImage = '';
        }
      }

      if (
        this.defaultProfile.profileImage.includes('bioguide.congress.gov') ==
        true
      ) {
        this.defaultProfile.profileImage = '';
      }

      //this.defaultProfile.tagName = this.user.defaultProfileTagName;
      //this.defaultProfile.id = this.user.defaultProfileId;
      this.nonVerifiedUser.firstName = this.user.firstName;
      this.nonVerifiedUser.lastName = this.user.lastName;
      this.nonVerifiedUser.userName = this.user.userName;

      this.pageRequest = 0;
      //this will make connection to websocket
      //  this.websocketService._connect(this.defaultProfile.id);
      this.getNotificationCount();
      //on init notification data load.
      this.getNotifications();
    }
  }

  logOut() {
    // Remove logIn flag in authservice
    this.authService.removeLogFlag();
    // Remove cookies when logOut
    this.authService.removeCookie();
    //close connection of websocket
    // this.websocketService._disconnect();
    // Navigate to Url
    this.router.navigateByUrl('/login');
  }

  // To search the profile based on the search string. Returns List of profiles matching the search string.

  headerSearchProfiles() {
    if (this.searchValue.startsWith(' ') || this.searchValue.endsWith(' ')) {
      return;
    } else {
      this.router.navigate(['/profile/view-profile/', this.searchValue]);
      this.searchValue = '';
    }
  }

  searchSelect(value: any) {
    this.searchValue = value.tagName;
    this.headerSearchProfiles();
  }
  checkLogin() {
    let user = this.authService.getUser();
    if (user != null) {
      if (user.verified) {
        if (user.defaultProfileId == null && user.reviewProfileId == null) {
          $('#restrictProfileModal').modal('show');
        } else if (user.reviewProfileId != null) {
          $('#restrictUnclaimedProfileModal').modal('show');
        } else {
          $('#conversionTabModal').modal('show')
        }
      } else {
        $('#restrictModal').modal('show');

      }
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  openFeedbackModal() {
    $('#haveFeedback').modal('show');
  }

  onChangeSearch(event: string) {
    // Trim leading and trailing spaces
    const trimmedEvent = event.trim();

    // Prevent search if the trimmed string is empty
    if (trimmedEvent === '') {
      this.searchSuggetionList = []; // Clear suggestions if the search string is empty
      // this.searchProfiles(); // Optionally call searchProfiles if needed
      return;
    }

    // Update search value
    this.searchValue = trimmedEvent;

    // Check for minimum length
    if (trimmedEvent.length > 1) {
      // Remove '@' from the beginning if present
      let searchTerm = trimmedEvent.startsWith('@') ? trimmedEvent.slice(1) : trimmedEvent;

      let params = new HttpParams()
        .set('tagNameSubString', searchTerm)
        .set('limit', '20');

      if (this.user && this.user.defaultProfileId) {
        params = params.set('profileId', this.user.defaultProfileId.toString());
      }

      this.profileService.getSuggestions(params).subscribe(
        (success) => {
          const newList: { tagName: any; displayName: string }[] = [];


          success.data.forEach((element: any) => {
            const tag = '@' + element.tagName;
            const displayName = `${tag}<br>${element.profileName}`;

            // Ensure there are no duplicates in the suggestions list
            if (!newList.some((e) => e.tagName === element.tagName)) {
              newList.push({ tagName: element.tagName, displayName });
            }
          });

          // Update list and detect changes
          this.searchSuggetionList = newList;
          this.value = this.searchSuggetionList[0];
        },
        (error) => {
          console.error('Error On Search:', error);
        }
      );
    }
  }

  //to get notification count
  getNotificationCount() {
    const params = new HttpParams().set(
      'profileId',
      Number(this.user.defaultProfileId).toString()
    );
    this.notificationService
      .getNotificationCountByProfileId(params)
      .subscribe((res) => {
        const resdata = res;
        if (resdata.success === 0) {
          console.log('notification count=' + resdata.data);
          this.notificationCount = resdata.data;
        }
      });
  }

  //get All notification
  getNotifications() {
    //this.notificationDataLoad = 'none';
    //this.notificationList = [];
    this.welcomeMessage = false;
    this.pageRequest = 0;
    if (this.user != null || this.authService.getLogflag()) {
      const params = new HttpParams()
        .set('profileId', Number(this.user.defaultProfileId).toString())
        .set('start', this.pageRequest.toString())
        .set('limit', '10');
      this.notificationService
        .getNotificationsByProfileId(params)
        .subscribe((res) => {
          const resData = res;
          if (resData.success === 0) {
            this.notificationList = [];
            this.notificationList = resData.data;
          } else {
            this.notificationList = [];
          }
          if (
            this.notificationList.length === 0 ||
            this.notificationList.length < 6
          ) {
            this.welcomeMessage = true;
          }
          //console.log('notifications:' + JSON.stringify(this.notificationList));
        });
    }
  }

  //update isRead Flag & notification count when click on notification
  updateNotification(notification: Notification) {
    let notifcationId = notification.id;
    let isRead = notification.isRead;
    console.log('NotificationId=' + notifcationId + '& isRead=' + isRead);
    if (!isRead) {
      const params = new HttpParams()
        .set('notificationId', Number(notifcationId).toString())
        .set('isRead', 'true');
      this.notificationService
        .updateNotificationByNotificationId(params)
        .subscribe((res) => {
          const resData = res;
          if (resData.success === 0) {
            this.notificationCount = Number(this.notificationCount) - 1;
          }
        });
    }
    console.log('updated notifi:', notification);
    this.routToNestedConversion(notification);
  }

  onAppear(event: any) {
    console.log('scroll call for notification');
    this.isInAppear = true;
    this.loading = '';
    this.pageRequest = Number(this.pageRequest) + 1;
    const params = new HttpParams()
      .set('profileId', Number(this.user.defaultProfileId).toString())
      .set('start', this.pageRequest.toString())
      .set('limit', '10');
    this.notificationService
      .getNotificationsByProfileId(params)
      .subscribe((res) => {
        const resData = res;
        if (resData.success === 0) {
          let addNotifications = new Array<Notification>();
          addNotifications = resData.data;
          if (addNotifications.length !== 0) {
            addNotifications.forEach((notification) => {
              this.notificationList.push(notification);
            });
          } else {
            this.welcomeMessage = true;
          }
          //console.log('Appended notification list=' + this.notificationList);
        }
        this.loading = 'none';
        this.isInAppear = false;
      });
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    //if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    console.log('End of notification scroll');
    if (this.isInAppear === false) {
      this.onAppear(event);
    }
    //}
  }

  routToNestedConversion(notification: Notification) {
    let url = '';

    if (notification.notification_type === 'MENTION') {
      url = notification.isReview
        ? `/profile/view-response/${notification.mentionReviewId}`
        : `/profile/review-response/${notification.reviewId}/${notification.mentionReviewId}`;
    } else {
      url = `/profile/view-profile/${notification.byProfileTagName}`;
    }

    this.router.navigateByUrl(url);
  }

  navigate() {
    window.location.href = '/login';
  }

  closeNavbarCollepse() {
    $('.navbar-collapse').removeClass('show');
  }

  searchOpen() {
    $('.header-search').addClass('small-screen-search');
    $('.input-container input').focus();
  }
  searchClose() {
    $('.header-search').removeClass('small-screen-search');
  }

  removeTags(str: string) {
    if (str === null || str === '') return '';
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  }

  openCreatePageModal() {
    $('#createPageModal').modal('show');
  }

  redirectToCreateProfilePage() {
    $('#createPageModal').modal('hide');
    this.router.navigate(['/profile/create-profile']);
  }

  deleteAccount() {
    const params = new HttpParams().set(
      'userId',
      Number(this.user.userId).toString()
    );
    this.userService.deleteUserAccount(params).subscribe((res) => {
      const resData = res;
      console.log('Delete Account API Response:' + resData);
      if (resData.success === 0) {
        $('#deleteFieldModal').modal('hide');
        this.logOut();
      }
    });
  }

  openDeleteAccountModal() {
    $('#deleteFieldModal').modal('show');
  }

  onEnter(event: Event): void {
    console.log('Enter key pressed', event);
    const keyboardEvent = event as KeyboardEvent; // Type assertion
    if (keyboardEvent.key === 'Enter') {
      // Check the key property
      if (this.value?.tagName) {
        this.router.navigate(['/profile/view-profile/', this.value.tagName]);
      } else {
        console.error('tagName is undefined');
      }
    }
  }

  aboutus() {
    $('#aboutUsModal').modal('show');
  }

  // onFocus(event: FocusEvent): void {
  //   event.stopImmediatePropagation();
  // }

  sendBugReportOrFeedback(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.reportBugFeedbackDTO.userId = this.user.userId;

    this.userService
      .sendBugReportOrFeedback(this.reportBugFeedbackDTO)
      .subscribe((res: any) => {
        const respData = res;
        if (respData.success == 0) {
          $('#haveFeedback').modal('hide');
          this.resetFormData(form);
        } else {
          $('#haveFeedback').modal('hide');
          this.resetFormData(form);
          console.log('Error while sending report bug or feedback');
        }
      });
  }

  resetFormData(form: NgForm) {
    form.resetForm();
    this.reportBugFeedbackDTO = new ReportBugFeedbackDTO();
    this.errMsg = '';
    this.activationsent = false;
    this.phoneNoErrMsg = '';
    this.otpResent=false;
    this.invalidOTP=false;
    this.incorrectActCode=false
    setTimeout(() => {
      this.email = this.user.email;
      this.phoneNumber = this.user.phoneNumber;
      this.countryCode = 'us';
    });
  }
  Updateaddress() {
    $('#UpdateAddressModal').modal('show');
  }

  verifymodal() {
    $('#UpdateAddressModal').modal('hide');
    $('#veriffModal').modal('show');
    console.log('veriff show');
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: 'b0f81b9e-fd96-48fb-8acb-5f7db5c97e91',
      //apiKey: '79d32573-88ce-4964-a446-d29cf7a19988',
      parentId: 'veriff-root',
      onSession: (err: any, response: any) => {
        console.log('veriff data : ' + response);
        console.log('veriff data : ' + JSON.stringify(response));
        (window as any).veriffSDK.createVeriffFrame({
          url: response.verification.url,
          onEvent: (msg: string) => {
            console.log(msg);
            if (msg == 'FINISHED') {
              console.log('verificaion finished');
              const params = new HttpParams().set(
                'userId',
                this.user.userId.toString()
              );
              this.userService.getUserDetail(params).subscribe((res: any) => {
                const respData = res;
                if (respData.success == 0) {
                  this.user = this.authService.getUser();
                  if (respData.data.veriffStatus == 'rejection' || respData.data.veriffStatus == 'declined') {
                    this.veriffStatus = respData.data.veriffStatus;
                    $('#veriffErrorModal').modal('show');
                    this.user.verified = true;
                    this.user.veriffFlag = true;
                    this.updateUser();

                  } else {
                    var user = this.authService.getUser();
                    if (respData.data.address != user.address) {
                      $('#UpdateAddressesModal').modal('show');
                    }
                    this.user.address = respData.data.address;
                    this.user.city = respData.data.city;
                    this.user.state = respData.data.state;
                    this.user.country = respData.data.country;
                    this.authService.setSessionData(this.user);
                    this.getRepresentative();
                  }
                }
              });
            }
          },
        });
        $('#veriffModal').modal('hide');
        this.veriffSessionId = response.verification.id;
        const params = new HttpParams()
          .set('userId', this.user.userId.toString())
          .set('sessionId', response.verification.id);
        this.userService.veriffDetails(params).subscribe((res) => {
          console.log('veriff api response:' + JSON.stringify(res));
          const respData = res;
          if (respData.success == 1) {
            this.veriffErrMsg = respData.message;
          }
        });

      },
    });
    veriff.setParams({
      person: {
        givenName: this.user.firstName,
        lastName: this.user.lastName,
      },
      vendorData: this.user.userId.toString(),
    });
    veriff.mount({});
  }

  getRepresentative() {
    let address = this.user.address;
    if (address != undefined && address != null) {
    address = address.replace(/\n/g, ' ');
    const params = new HttpParams().set('address', address);
    this.loaderService.disable = true;

    this.profileService.getReprentatives(params).subscribe((res) => {
      if (res.success === 0) {
        console.log(JSON.stringify(res));
        res.data.forEach((obj: any) => {
          this.representativeList.push(obj.tagname);
        });
        console.log('Representative List:' + JSON.stringify(this.representativeList));
        this.authService.setRepresentativeList(this.representativeList);
        // window.location.reload();

        if (this.router.url.includes('view-response')) {
          console.log('URL contains "view-response", reloading page.');
          window.location.reload();
        }
      }
    });
  }
  }
  onFocus() {
    this.showSuggestions = true;
  }
  // Hide suggestions when input loses focus
  onBlur() {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200);
  }

  // Purpose : For updating user
  updateUser() {
    this.userService.editUser(this.user).subscribe((res) => {
      this.authService.setSessionData(this.user);
      if (res.success == 0) {
        console.log('User updated successfully:' + JSON.stringify(this.user));
      } else {
        console.log('Error while updating user');
      }
    });
  }

  closePopup() {
    window.location.reload();
  }
  phonoModal(){
    console.log("clicked")
    $('#phoneNumberModalHeader').modal('show');
  }
  changePhoneNumber() {
    this.otpSent = false;
  }

  resendOTP(form: NgForm) {
    form.resetForm();
    this.sendOTP(false);
    this.otpResent = true;
  }
  //to send OTP
    sendOTP(invalid: any) {
      if (invalid) {
        return;
      }
      if (this.phoneNumber != null && this.user.phoneNumber == this.phoneNumber) {
        this.phoneNoErrMsg = 'You have entered the same phone no. Please enter a new phone no.';
        return;
      }
      this.loaderService.disable = true;
      let phoneNumberString: string;
      let prefix: string = '';
      phoneNumberString = prefix + this.phoneNumber.toString();

          //API call
          const params = new HttpParams()
            .set('userId', this.user.userId.toString())
            .set('countryCode', this.countryCode)
            .set('phoneNumber', this.phoneNumber.toString())
            .set('updatedFlag', true);
          this.userService.sendOTP(params).subscribe((res) => {
            const respData = res;
            if (respData.success == 0) {
              $('#phoneNumberModalHeader').modal('hide');
              $('#phoneOtpModal').modal('show');
              return;
            } else {
              this.invalidPhone = true;
            }
          });  
    }
  
  //to verify OTP
  otpVerify(form: NgForm) {
      if (form.invalid) {
        return;
      }
      this.loaderService.disable = true;
  
      this.otp = this.otpCodeArr.join('');
      //API call
      const params = new HttpParams()
        .set('userId', this.user.userId.toString())
        .set('otp', this.otp)
        .set('phoneNumber', this.phoneNumber);
      this.userService.verifyOTP(params).subscribe((res) => {
        const respData = res;
        if (respData.success == 1) {
          this.invalidOTP = true;
          return;
        } else {
          form.resetForm();
          $('#phoneOtpModal').modal('hide');
          this.invalidOTP = false;
          this.otpSent = false;
          this.otpResent = false;
          this.otpCodeArr = new Array(4).fill('');

          const params = new HttpParams().set(
            'userId',
            this.user.userId.toString()
          );
          this.userService.getUserDetail(params).subscribe((res) => {
            const respData = res;
            if (respData.success == 0) {
              this.user.phoneNumber = respData.data.phoneNumber;
              this.authService.setSessionData(this.user);
              this.phoneNumber = respData.data.phoneNumber;
            }
          });
        }
      });
    }
    onPestCode(event: ClipboardEvent, id: any, len: any) {
      let clipboardData = event.clipboardData || (window as any).clipboardData;
      let pastedText = clipboardData.getData('text').trim();
      let myIndex = 0;
      const element = document.getElementById(`${id}${len - 1}`);
      if (element) {
        (element as HTMLInputElement).focus();
      }
      for (var i = 0; i < len; i++) {
        const myIndex2 = id + myIndex;
        const input: any = document.getElementById(myIndex2);
  
        // pest value in array
        input.value = pastedText.charAt(i);
        const event = new Event('input', {
          bubbles: true,
          cancelable: true,
        });
        input.dispatchEvent(event);
  
        // pest value in input view
        input.value = pastedText.charAt(i);
  
        myIndex = myIndex + 1;
      }
    }
    keytab(myIndex: any, key: any, event: any) {
      if (event === 'keyup' && (key.keyCode === 8 || key.keyCode === 46)) {
        key.target.value = '';
        if (myIndex > 0) {
          myIndex = myIndex - 1;
          const myIndex2 = 'activationCode' + myIndex;
          const element = document.getElementById(myIndex2);
          if (element) {
            (element as HTMLInputElement).focus();
          }
        }
      }
      if (event === 'input' && myIndex < 5 && key.target.value !== '') {
        if (key.data in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
          myIndex = myIndex + 1;
          const myIndex2 = 'activationCode' + myIndex;
          const element = document.getElementById(myIndex2);
          if (element) {
            (element as HTMLInputElement).focus();
          }
        } else {
          key.target.value = '';
        }
      }
    }
  
    keytabOTP(myIndex: any, key: any, event: any) {
      if (event === 'keyup' && (key.keyCode === 8 || key.keyCode === 46)) {
        key.target.value = '';
        if (myIndex > 0) {
          myIndex = myIndex - 1;
          const myIndex2 = 'otpCode' + myIndex;
          const element = document.getElementById(myIndex2);
          if (element) {
            (element as HTMLInputElement).focus();
          }
        }
      }
      if (event === 'input' && myIndex < 3 && key.target.value !== '') {
        if (key.data in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
          myIndex = myIndex + 1;
          const myIndex2 = 'otpCode' + myIndex;
          const element = document.getElementById(myIndex2);
          if (element) {
            (element as HTMLInputElement).focus();
          }
        } else {
          key.target.value = '';
        }
      }
    }
    
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  updateemail(){
    $('#emailModal').modal('show');
  }
  setotp(){
    $('#otpModal').modal('show');
    $('#emailModal').modal('hide');
  }

  // To update email or phone no.
  updateEmailOrPhoneNo(countryCode: string, phoneNo: string, email: string, form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.email != '' && this.email === this.user.email) {
      this.errMsg = 'You have entered the same email. Please enter a new email.';
      return;
    }
    if (this.user != undefined) {

      let params = new HttpParams().set('userId', this.user.userId);

      if (email != '') {
        params = params.append('email', this.email);
      }
      if (countryCode != '' && phoneNo != '') {
        params = params.append('countryCode', this.countryCode);
        params = params.append('phoneNo', this.phoneNumber);
      }

      this.userService
        .updateEmailOrPhoneNumber(params)
        .subscribe((res: any) => {
          console.log("UpdateEmailOrPhoneNumber API Response:" + JSON.stringify(res));
          if (res.success == 0 && email != '') {
            $('#otpModal').modal('show');
            $('#emailModal').modal('hide');

          } else if (res.success == 0 && phoneNo != '') {
            $('#phoneNumberModalHeader').modal('show');
          } else {
            this.errMsg = res.message;
          }

        });
    }
  }

  // To verify activation code
  activationCodeVerify(invalid: any) {
    if (invalid) {
      return;
    }

    this.verificationCode = this.actCodeArr.join('');

    const params = new HttpParams()
      .set('userId', this.user.userId.toString())
      .set('activationCode', this.verificationCode)
      .set('email', this.email);

    this.userService.activationCodeVerify(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
        this.incorrectActCode = true;
        return;
      } else {
        this.incorrectActCode = false;
        $('#otpModal').modal('hide');
        $('#emailSucessfully').modal('show');

      }
    });
  }

  //  this method is called when clicking the resend button in update email popup.
  resendActivationCode(form: NgForm) {
    form.resetForm();
    const params = new HttpParams().set('userId', this.user.userId.toString()).set('updatedflag', true)
      .set('updatedEmail', this.email);
    this.userService.resendActivationCode(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
      } else {
        this.activationsent = true;
      }
    });
    return;
  }

  onFocusEvent(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    target.select();
  }

  getUserDetails() {
    this.user = this.authService.getUser();
    if (this.user != undefined) {
      const params = new HttpParams().set('userId', this.user.userId.toString());
      this.userService.getUserDetail(params).subscribe((res: any) => {
        const respData = res;
        if (respData.success == 0) {
          this.user.defaultProfileId = respData.data.defaultProfileId;
          this.user.defaultProfileTagName = respData.data.defaultProfileTagName;
          this.user.defaultProfileName = respData.data.defaultProfileName;
          console.log("getuser detail:" + JSON.stringify(this.user))
          this.defaultProfile.tagName = this.user.defaultProfileTagName;
          this.defaultProfile.id = this.user.defaultProfileId;
          this.authService.setSessionData(this.user);
        }
      });
    }
  }
}
