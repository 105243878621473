import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  getUserDetail(params: HttpParams) {
    return this.apiService.get('rest/user/getUserDetail', params);
  }

  constructor(private apiService: ApiService) {}

  // for htting tha backedn api for logging in
  login(request: object) {
    return this.apiService.post('rest/auth/authenticate', request);
  }

  // for set veriffDetails
  veriffDetails(params: HttpParams) {
    return this.apiService.postParams('rest/user/veriffDetails', params);
  }

  //for hitting the addUser Api for adding partial detials from the first page of signup
  signUp(request: object) {
    return this.apiService.post('rest/user/', request);
  }

  //for hitting the addUser Api for adding partial detials from the first page of signup
  editUser(request: object) {
    return this.apiService.put('rest/user/', request);
  }

  //For hitting API sendResetPasswordLink for sending a reset password link
  forgotPwd(params: HttpParams) {
    return this.apiService.postParams(
      'rest/user/sendResetPasswordLink',
      params
    );
  }

  //For hitting this API resetPassword for when user clicks on the link sent to him for resetting the password
  resetPwd(params: HttpParams) {
    return this.apiService.postParams('rest/user/resetPassword', params);
  }

  //for hitting the api for  verifying activation code
  activationCodeVerify(params: HttpParams) {
    return this.apiService.postParams('rest/user/activationCodeVerify', params);
  }

  resendActivationCode(params: HttpParams) {
    return this.apiService.postParams('rest/user/resendActivationCode', params);
  }

  // for hitting the api for setting and storing username and password for the first time.
  activateProfile(request: object) {
    return this.apiService.post('rest/user/setPassword', request);
  }

  //for checking if the username already exists.
  checkExistingUsername(params: HttpParams) {
    return this.apiService.postParams(
      'rest/user/checkExistingUserName',
      params
    );
  }

  //for checking if the phone number already exists.
  checkExistingPhoneNumber(params: HttpParams) {
    return this.apiService.postParams(
      'rest/user/checkExistingPhoneNumber',
      params
    );
  }

  //for sending OTP
  sendOTP(params: HttpParams) {
    return this.apiService.postParams('rest/user/sendOTP', params);
  }

  //for verifying OTP
  verifyOTP(params: HttpParams) {
    return this.apiService.postParams('rest/user/otpVerify', params);
  }

  //For deleting user account
  deleteUserAccount(params: HttpParams) {
    return this.apiService.delete('rest/user/', params);
  }
  // For fetching text verified user details
  fetchTextVerifiedUser() {
    return this.apiService.get('rest/user/textVerified');
  }

  // For updating user verification details
  updateUserVerificationDetails(params: HttpParams) {
    return this.apiService.putParams('rest/user/verificationDetails', params);
  }

  //for unsubscribing mail
  unsubscribeMail(params: HttpParams) {
    return this.apiService.postParams('rest/user/unsubscribe', params);
  }

  fetchUserMailSubscription(params: HttpParams) {
    return this.apiService.get('rest/user/unsubscribe', params);
  }

  // for sending bug report/feedback of user
  sendBugReportOrFeedback(request: object) {
    return this.apiService.post('rest/user/reportABugOrFeedback', request);
  }

  // For updating email or phone no
  updateEmailOrPhoneNumber(params: HttpParams) {
    return this.apiService.putParams('rest/user/emailOrPhoneNumber', params);
  }
}
