<!-- signin -->
<div class="content pt-md-5 m-0 signin">
  <div class="row m-0 py-2 center">
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1 signin-col">
      <h1 class="text-center text-md-left title">Welcome</h1>
      <p class="text-center text-md-left py-1 sub-title">
        Please enter your registered Email address and Password
      </p>
    </div>

    <div class="col-12 col-sm-8 col-md-8 col-lg-6 offset-sm-2 offset-md-1">
      <form
        class="row m-0 square-form needs-validation"
        (ngSubmit)="validateLogin(signinForm)"
        #signinForm="ngForm"
        novalidate
        autocomplete="off"
      >
        <div
          class="col-md-12 my-2 alert alert-danger"
          *ngIf="this.invalidLogin == true"
        >
          Incorrect email or Password
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            (click)="invalidLogin = false"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>
        <!-- .alert-dangers -->

        <div class="col-md-12 px-0">
          <div class="form-group">
            <input
              type="text"
              [ngClass]="{
                'form-control': true,
                'has-value': !!userName.value
              }"
              id="userName"
              name="userName"
              ngModel
              #userName="ngModel"
              tabindex="1"
              required
              autocomplete="new-username"
              onfocus="this.setAttribute('autocomplete', 'off')"
            />
            <label for="userName">Email Address</label>
            <div
              class="invalid-feedback"
              *ngIf="signinForm.submitted && userName.invalid"
            >
              <span *ngIf="userName.errors?.['required']"
                >Email is required.</span
              >
            </div>
          </div>
        </div>

        <div class="col-md-12 px-0">
          <div class="form-group">
            <input
              type="password"
              [ngClass]="{ 'form-control': true, 'has-value': !!pwd.value }"
              id="password"
              name="password"
              minlength="8"
              maxlength="40"
              ngModel
              #pwd="ngModel"
              tabindex="2"
              required
              autocomplete="new-password"
              onfocus="this.setAttribute('autocomplete', 'off')"
            />
            <label for="password">Password</label>
            <button
              type="button"
              class="right-icon toggle-password"
              *ngIf="!!pwd.value"
              toggle="#password"
              tabindex="4"
            >
              <i class="fi flaticon-eye"></i>
            </button>
            <div
              class="invalid-feedback"
              *ngIf="signinForm.submitted && pwd.invalid"
            >
              <span *ngIf="pwd.errors?.['required']">Password is required</span>
              <span *ngIf="pwd.errors?.['minlength']">
                Password must be at least 8 characters long.</span
              >
              <span *ngIf="pwd.errors?.['maxlength']">
                Password is limited upto 40 characters.</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 px-0 text-right mt-2">
          <a
            [routerLink]="['/forgotPassword']"
            class="forgot-password font-12"
            tabindex="7"
          >
            Forgot Password ?
          </a>
        </div>
        <div class="col-12 px-0">
          <div class="form-group mt-0">
            <div class="checkbox">
              <input
                type="checkbox"
                id="remMe"
                name="remMe"
                ngModel
                #remMe="ngModel"
                tabindex="5"
              />
              <label for="remMe"> Remember Me </label>
            </div>
          </div>
        </div>
        <div class="col-12 px-0 mt-3">
          <input
            type="submit"
            class="btn btn-black w-100"
            value="SIGN IN"
            tabindex="6"
          />

          <p class="font-14 font-gray text-center mt-3">
            Don't have an account ?
            <a
              [routerLink]="['/signup']"
              class="btn btn-link btn-link-theme font-14 p-0"
              tabindex="8"
            >
              Create Yours Now
            </a>
          </p>
        </div>
        <!-- .col -->
      </form>
    </div>
    <!-- .col-md-12 -->
  </div>
  <!-- .row -->
</div>
<!-- .content -->
<!-- signin Ends -->
